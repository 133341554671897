/* rgb(9, 42, 77) , 053d72, 065197 darker blues*/
/* #247ca5 footer */

/* Sound cloud */
.soundcloud {
  max-width: 375px;
  max-height: 375px;
  margin: auto;
  border-radius: 10px;
}

.soundcloud > iframe {
  padding-bottom: 15px;
}


/* images */
.default-image {
  border-radius: 25px;
}

.default-caption {
  text-align: center;
  margin-top: 1rem;
  background-color:  #247ca542;
  border-radius: 25px;
}

.default-caption > span {
  font-family: 'Pangolin', cursive;
  font-size: 25px;
}

/*header */
.jumbotron {
  box-shadow: 0 4px 8px 0 rgba(6, 68, 138, 0.699);
  z-index: 1;
  overflow: hidden;
}

#name {
  color:rgb(15, 54, 95);
  text-shadow: 1px 1px #1e6b8f, 2px 2px #1e6b8f, 3px 3px #1e6b8f, 4px 4px #1e6b8f
}

#name:hover {
  position: relative;
  top: -2px;
  left: -2px; 
  text-shadow: 1px 1px #1e6b8f, 2px 2px #1e6b8f, 3px 3px #1e6b8f, 4px 4px #1e6b8f, 5px 5px #1e6b8f, 6px 6px #1e6b8f
}

.home-header {
  margin-bottom: 0rem;
  background-image: url('./assets/header-background.jpg');
  background-size: cover;
}
.basic-header {
  text-align: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
  margin-bottom: 0rem;
  background-color: #247ca5;
  background-image: url('./assets/header-background.jpg');
  background-position: 0% 10%;
  background-size: cover;
}

#welcome-wrapper {
      float: left;
      padding-left: 5%;
      padding-top: 2%;
      background-color: rgba(158, 207, 255, 0.425);
      border-radius: 25px;
}

/* nav */
.nav {
  margin-top: 4px;
  margin-bottom: 4px;
  box-shadow: 0 4px 8px 0 rgba(6, 68, 138, 0.699);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.nav-tabs > .nav-item > .nav-link {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  }

.nav-link {
  font-family: 'Pangolin', cursive;
  font-size: 30px;
}
.nav-item {
  z-index: 1
}
.nav-item:hover {
  transform:scale(1,1.1);
}

.nav-subtitle {
  text-align: center;
  font-family: 'Pangolin', cursive;
  font-size: 20px;
}

.dropdown-menu {
  margin: auto;
  width: 97.5%;
  font-family: 'Varela Round', sans-serif;
  padding: 0rem;
  align-content: center;
}

.dropdown-label {
  font-size: larger;
}

/* Project */
.project-body {
  background-image: url('./assets/light-blue-1.jpg');
  background-size: cover;
  padding-bottom: 30px;
}
.project-list-li {
  margin-bottom: 0.5em;
}

/* Card */
.card-wrapper {
  margin-top: 30px;
}
.card {
  border: 3px solid #247ca5;
  border-radius: calc(.5rem - 1px);
}
.card-img-top {
  padding: 0em;
}
.card-body {
  background-color: #cee8f0;
  font-size: 1.1rem;
}
.card-desc {
  margin-top: 1em;
}
.card-link {
  color: #247ca5;
}
.card-link-2 {
  color: #053d72;
  font-weight: 1000;
  word-spacing: 0em;
}

/* Body */
body {
  height: 100%;
  width: 100%;
}

#body-wrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
  margin-top: 0rem;
  padding-top: 2rem;
  background-size: cover;
  background-image: url(./assets/light-blue-1.jpg);
  background-size: cover;
  margin-bottom: 0px;
  box-shadow: 0 4px 8px 0 rgba(2, 21, 43, 0.699);
}
.content-1 {
  margin-top: 2rem;
  align-content: center;
  margin-bottom: 2rem;
}
.content-2 {
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: rgba(252, 253, 253, 0.225);
  border-radius: 25px;
  margin:auto;
}

.content-header-wrapper {
  display: block;
  margin-bottom: 2rem;
  background-color: #247ca542;
  border-radius: 25px;
  text-align: center;
}

.content-header {
  font-size: 45px;
  color:#1a5774;
}

.content-body {
  font-family: 'Varela Round', sans-serif;
  font-size: 1.5rem;
  font-weight: 550
}

#about-me-icon {
  width: 1.5em;
  height: 1.5em;
  animation-name: rocking-sun;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0s;
  animation-timing-function: linear;
  transition: transform 2s linear;
}

/* buttons */
.default-button-wrapper-2 {
  position: relative;
}
.default-button {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-tranform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #247ca5;
  border-width: 0;
}

.default-button:hover {
  background-color: #2b96c7;
}
.default-button:active {}
.default-button:focus {}

.button-text {
  font-family: 'Pangolin', cursive;
  font-size: 25px;
}

/* colours */
.title-gold {
  color: #FFD700;
}
.title-orange {
  color: #FF8C00;
}
.title-white {
  color: #FFFFFF;
}
.title-amber {
  color: #ffbf00;
}

.light-blue {
  background-color: #cee8f0;
}
.sky-blue {
  background-color: #87ceeb;
}

/* fonts */
h1 {
  font-family: 'Comic Neue', cursive;
  font-size: calc(45px + 6vw);
}
h2 {
  font-family: 'Comic Neue', cursive;
  font-size: calc(35px + 4vw);
}
h3 {
  font-family: 'Comic Neue', cursive;
  font-size: calc(25px + 1.75vw);
}
h4 {
  font-family: 'Comic Neue', cursive;
  font-size: 25px;
}
h5 {
  font-family: 'Comic Neue', cursive;
  font-size: 25px;
}
.shadow {
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}
/* alignment */
.center{
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes rocking-sun {
  0% {transform: rotate(0deg);}
  25% {transform: rotate(-40deg);}

  75% {transform: rotate(40deg);}
  100% {transform: rotate(0deg);}
}

/*logo animation*/
#logo {
  transform: rotate(0deg);
  animation-name: rocking-sun;
  animation-duration: 4s;
  animation-iteration-count: 1;
  animation-delay: 0.5s;
  animation-timing-function: linear;
  transition: transform 2s linear;
}

#logo:hover {
  transform: rotate(360deg);
}

/*Footer */
#footer {
  background-color: #247ca5;
  height: 100%;
  padding-bottom: 2rem;
}
.footer-content {
  padding-top: 5rem; padding-left: 2rem; 
  color: #FFFFFF;
  font-size: 1.2rem;
  font-family: 'Varela Round', sans-serif;
}
.footer-content > a {
  color: #c2cffa;
}
.footer-logo-wrapper {
  transform: rotate(0deg);
  padding: 3rem;
}

#footer-logo {
  transform: rotate(0deg);
  transition: transform 2s linear;
  min-width: 125px;
}
#footer-logo:hover {
  transform: rotate(-360deg);
}

#footer-icon{
  animation-name: rocking-sun;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0s;
  animation-timing-function: linear;
  transition: transform 2s linear;
}

/*media queries */
/* small */
@media screen and (max-width: 475px) {
  #name {
    font-size: calc(32px + 6vw);
  }

  .welcome-message {
    font-size: calc(15px + 4vw);
  }
  
  .content-2 {
    padding-left: 1rem;
    padding-right: 1rem;
    margin:auto;
  }
  .content-header {
    font-size: 30px;
  }
  
  .content-body {
    font-family: 'Varela Round', sans-serif;
    font-size: 1.2rem;
    font-weight: 550
  }
  
}

/* medium */
/* switch to mobile nav at this breakpoint */
@media screen and (max-width: 767px) {
  #name {
    color:rgb(15, 54, 95);
    text-shadow: 1px 1px #1e6b8f, 2px 2px #1e6b8f
  }
  
  #name:hover {
    position: relative;
    top: -1px;
    left: -1px; 
    text-shadow: 1px 1px #1e6b8f, 2px 2px #1e6b8f, 3px 3px #1e6b8f
  }
  
  .logo-media {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2rem;
      margin-top: 2rem;
      width: 50%;
  }

  #footer-logo {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
  }
  .footer-content {
    text-align: center;
    padding-left: 0.5rem;
  }
  .img-media {
      display: block;
      margin-left: auto;
      margin-right: auto;
  }
  #welcome-wrapper {
      margin: auto;
      width: 80%;
  }
  .nav-link {
    font-size: 20px;
  }
  .nav-subtitle {
    font-size: 20px;
    margin-bottom: 0rem;
  }

  /* optional mobile nav styling */
  .dropdown-toggle::after {
    content: none;
  }
  .nav {
    position: fixed;
    z-index: 1000;
    top: -4px;
    right: 0;
    width: 100%;
  }
  #body-wrapper {
    margin-top: 4px;
  }

  a.disabled {
    pointer-events: none;
  }
  .basic-header {
    padding-top: 4rem;
  }
}

